import {CustomIconComponentProps} from "@ant-design/icons/es/components/Icon";
import Icon from "@ant-design/icons";
import React from "react";

const MacaronSvg = (size: number) => (
    <svg height={size + "px"} width={size + "px"} fill="currentColor" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 512 512">
        <g>
            <g>
                <path d="M354.88,201.665h-0.137c-5.77,0-10.449,4.678-10.449,10.449c0,5.771,4.679,10.449,10.449,10.449h0.137
			c5.77,0,10.449-4.678,10.449-10.449C365.329,206.343,360.65,201.665,354.88,201.665z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M235.588,157.78h-0.138c-5.77,0-10.449,4.678-10.449,10.449c0,5.771,4.679,10.449,10.449,10.449h0.138
			c5.77,0,10.449-4.678,10.449-10.449C246.037,162.458,241.358,157.78,235.588,157.78z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M276.55,333.322h-0.138c-5.77,0-10.449,4.678-10.449,10.449s4.679,10.449,10.449,10.449h0.138
			c5.77,0,10.449-4.678,10.449-10.449S282.32,333.322,276.55,333.322z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M479.608,206.31v-12.139c0-69.615-59.999-126.253-133.747-126.253H166.139c-73.748,0-133.747,56.638-133.747,126.254
			v12.139C13.334,214.76,0,233.846,0,256s13.334,41.24,32.392,49.69v12.139c0,69.615,59.999,126.253,133.747,126.253h179.722
			c73.748,0,133.747-56.638,133.747-126.254v-12.139C498.666,297.24,512,278.153,512,255.999S498.666,214.76,479.608,206.31z
			 M458.71,317.828c0,5.263-0.421,10.436-1.223,15.495H310.355c-5.77,0-10.449,4.678-10.449,10.449s4.679,10.449,10.449,10.449
			h141.413c-15.904,40.214-57.375,68.963-105.907,68.963H166.139c-62.226,0-112.849-47.262-112.849-105.356v-7.52
			c0.349,0.006,0.695,0.026,1.045,0.026h403.331c0.35,0,0.696-0.02,1.045-0.026V317.828z M457.665,289.437H54.335
			c-18.437,0-33.437-15-33.437-33.437s15-33.437,33.437-33.437h266.604c5.77,0,10.449-4.678,10.449-10.449
			c0-5.771-4.679-10.449-10.449-10.449H54.335c-0.35,0-0.696,0.02-1.045,0.026v-7.52c0-5.263,0.421-10.436,1.224-15.495h147.133
			c5.77,0,10.449-4.678,10.449-10.449c0-5.771-4.679-10.449-10.449-10.449H60.232c15.904-40.213,57.375-68.962,105.907-68.962
			h179.722c62.226,0,112.849,47.262,112.849,105.356v7.52c-0.349-0.006-0.695-0.026-1.045-0.026h-67.918
			c-5.77,0-10.449,4.678-10.449,10.449c0,5.771,4.679,10.449,10.449,10.449h67.918c18.437,0,33.437,15,33.437,33.437
			C491.102,274.438,476.103,289.437,457.665,289.437z"/>
            </g>
        </g>
    </svg>
)

export const MacaronIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={() => MacaronSvg(18)} {...props} />
);

export const SmallMacaronIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={() => MacaronSvg(14)} {...props} />
);