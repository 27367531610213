import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';

import { createRoot, hydrateRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';

const rootElement = document.getElementById("root");

const themeConfig = {
    token: {
        colorPrimary: "#B99095",
        colorTextHeading: "#B99095",
        borderRadius: 8,
    },
    components: {
        Layout: {
            // Layout-specific configurations
        }
    }
};

// @ts-ignore
if (rootElement.hasChildNodes()) {
    // This is for server-side rendering (SSR)
    // @ts-ignore
    hydrateRoot(rootElement, (
        <ConfigProvider theme={themeConfig}>
            <App />
        </ConfigProvider>
    ));
} else {
    // This is for client-side rendering (CSR)
    // @ts-ignore
    createRoot(rootElement).render(
        <ConfigProvider theme={themeConfig}>
            <App />
        </ConfigProvider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
