import React, {FC} from "react";
import {Rate} from "antd";
import {MacaronIcon} from "../../main/component/MacaronIcon";
import {ClientOrder} from "../model/ClientOrder";

interface AlertBoxProps {
    boxSize: number;
    ordered: number;
}

export const Box: FC<AlertBoxProps> = ({boxSize, ordered}) => {
    if (boxSize < 11) {
        return (
            <Rate
                style={{color: '#B99095'}}
                disabled={true}
                count={boxSize}
                value={ordered}
                character={<MacaronIcon/>}/>
        )
    } else {
        let countInRow = Math.round(boxSize / 2);
        return (
            <>
                <Rate
                    style={{color: '#B99095'}}
                    disabled={true}
                    count={countInRow}
                    value={ordered <= countInRow ? ordered : countInRow}
                    character={<MacaronIcon/>}/>
                <br/>
                <Rate
                    style={{color: '#B99095'}}
                    disabled={true}
                    count={countInRow}
                    value={ordered > countInRow ? ordered - countInRow : 0}
                    character={<MacaronIcon/>}/>
            </>
        )
    }
}

interface ShowBoxProps {
    clientOrder: ClientOrder;
}

export const ShowBox: FC<ShowBoxProps> = ({clientOrder}) => {
    return <Box boxSize={clientOrder.boxSize} ordered={clientOrder.added}/>
}