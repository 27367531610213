import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: {
                    "typeMacarons": "Macarons",
                    "typeCheesecakes": "Cheesecakes",

                    "cheescakePreorded": "Cheesecakes are available on pre-order on Instagram",
                    "cheescakeMintBlackCurrant": "Mint Black Currant",
                    "cheescakeMango": "Mango",
                    "cheescakePistachioRaspberry": "Pistachio Raspberry",
                    "cheescakeSnickers": "Snickers",

                    "introText": "Premium French macarons in Tbilisi",
                    "location": "Tbilisi, Georgia",
                    "order": "Order now",
                    "flavours": "Our macarons flavours",
                    "allFlavoursAvailable": "All flavours are in stock and available to order",
                    "orderViaInstagram": "Order via Instagram",
                    "giftBoxes": "Macarons gift boxes",
                    "macarons": "macarons",

                    "excellentQuality": "Excellent quality",
                    "premiumIngredients": "Premium Ingredients",
                    "almondFlour": "BORGES® Almond flour",
                    "eggWhites": "GASTRONOME® Free Range Eggs",
                    "parmigianoReggiano": "Parmigiano Reggiano DOP",
                    "camembertAndBrie": "French Camembert and Brie",
                    "glutenFree": "Gluten-free",
                    "sugarPowder": "Sugar/sugar powder",

                    "finestBelgianChocolate": "Finest Belgian Chocolate",

                    "frenchFruitPuree": "French Fruit Puree",
                    "mango": "DGF® Mango Fruit Puree",
                    "raspberry": "DGF® Raspberry Fruit Puree",
                    "blackCurrant": "DGF® Black Currant Fruit Puree",
                    "strawberry": "DGF® Strawberry Fruit Puree",

                    "nutPaste100": "Nut Paste 100%",
                    "pistachio": "Pistachio",
                    "peanut": "Peanut",
                    "coconut": "Coconut",
                    "hazelnut": "Hazelnut",

                    "allergens": "Allergens",
                    "dairy": "Dairy",
                    "egg": "Eggs",
                    "nuts": "Nuts",
                    "allergensNotice1": "All macarons contain nuts, eggs, and dairy products, the ingredients vary depending on flavour.",
                    "allergensNotice2": "If you have a food allergy, please carefully study the macaron ingredients (by clicking on the card) or contact us for clarification.",

                    "ingredients": "Ingredients",

                    "forYourConvenience": "For your convenience",

                    "storageAndService": "Storage and serving",
                    "take15minutesBefore": "Take out the box from the fridge 15\u00a0minutes before serving.",
                    "keepInFridge": "Keep in the bottom of your fridge within 5\u00a0days.",

                    "delivery": "Delivery",
                    "deliveryTerms1": "Delivery via the Bolt® Delivery or Yandex® Delivery applications is charged separately.",
                    "deliveryTerms2": "Pickup is possible.",

                    "composeYourBox": "Preparation",
                    "composeYourBoxTerms1": "The order is prepared in 1 hour from the available flavors.",
                    "composeYourBoxTerms2": "Corporate orders are prepared in 3-5\u00a0days.",

                    "workingHours": "Working hours",
                    "workingHoursTerms": "We work every day from 10\u00a0to\u00a022.",
                    "yourOrderWillBeProcessedDuringBusinessHours": "You can order now, we will deliver from 10:00.",

                    "chooseAnyFlavour": "Choose any flavours you like",

                    "createYourBox": "Create your box",
                    "availableBoxes": "Available boxes: 5, 10, 16, and 20\u00a0macarons",
                    "justKeepAdding": "Just keep adding or removing a flavour to make box bigger or smaller",

                    "yourOrder": "Your order",
                    "yourOrderDetailsWillAppear": "Your order details will appear here",
                    "total": "Total",
                    "clearOrder": "Clear order",
                    "confirmOrder": "Confirm order",
                    "completeBoxFirst": "Please, complete your box first ❤️",

                    "yourBox": "Your box",
                    "added": "added",
                    "removed": "removed",
                    "yourBoxIsReady": "Your box is ready to order!",
                    "keepAddingBox": "Add more to make your box bigger.",


                    "minimalOrder": "Minimal order is 5 macarons",
                    "orderNumber": "Order number",

                    "boxContentReceived": "We have received your order ❤️",
                    "deliveryDetails": "Delivery is available via the Bolt® Delivery or Yandex® Delivery.",
                    "youCanPayWithCardOrCash": "You can pay with a card, or pay with\u00a0cash upon pick up.",

                    "close": "Close",

                    "yourName": "Your name",
                    "yourPhone": "Your phone number",
                    "yourEmail": "Your E-mail",
                    "messenger": "Messenger",
                    "inputYourName": "Please enter your name",
                    "inputYourPhone": "Please enter your phone number",
                    "chooseMessengerType": "Please choose a messenger type",
                    "longValue": "The value is too long",
                    "send": "Send",
                    "sent": "Sent",

                    "inStockOnly": "Currently in stock: ",
                    "tryOtherFlavours": "Try other flavours ❤️",

                    "reserved": "reserved",
                    "areYourSureToCleanConfirmedOrder": "Are you sure you want to clear confirmed order?",
                    "yes": "Yes",
                    "no": "No",

                    "assorted": "Assorted",

                    "contacts": "Contacts",
                    "weWillBeHappyToHelpYou": "We are happy to help you with any questions ❤️",
                    "reachUsDaily": "Contact us every day from\u00a009:00\u00a0to\u00a022:00\u00a0GMT+4",
                    "instagram": "Instagram",
                    "whatsapp": "WhatsApp",
                    "telegram": "Telegram",
                    "email": "E-mail",

                    "payment": "Payment",
                    "weAcceptVisaAndMastercard": "We accept online payments",

                    "creatingYourOrder": "Creating your order ❤️",
                    "itWillTakeTime": "It will take a couple of seconds...",
                    "onlinePayment": "Online payment",

                    "paymentStatus": "Payment status",
                    "paymentStatus_CREATED": "Payment request is created",
                    "paymentStatus_PROCESSING": "Payment is being processed",
                    "paymentStatus_COMPLETED": "Payment process has been completed",
                    "paymentStatus_REJECTED": "Payment process has been unsuccessfully completed",
                    "paymentStatus_REFUND_REQUESTED": "Refunding of the amount is requested",
                    "paymentStatus_REFUNDED": "Payment amount has been returned",
                    "paymentStatus_REFUNDED_PARTIALLY": "Payment amount has been partially refunded",
                    "paymentStatus_ERROR": "Payment error (please, contact us for details)",
                    "paymentStatus_PARTIAL_COMPLETED": "Pre-authorize payment partial amount has been confirmed successfully",

                    "receipt": "Receipt",
                    "treatYourself": "Treat yourself",

                    "districtVakeAndBagebi": "Vake, Bagebi",
                    "districtSaburtaloAndVeraAndChughureti": "Saburtalo, Vera, Chughureti",
                    "districtDidubeAndSololakiAndDzveli": "Didube, Sololaki, Dzveli Tbilisi",
                    "districtAvlabari": "Avlabari",
                    "districtIsaniAndVarketili": "Isani, Varketili",
                    "districtOther": "Other district",

                    "orderComment": "Comment",
                    "pickup": "Pickup",
                    "yourDeliveryAddressDistrict": "Your district",
                    "totalWithDelivery": "Total (with delivery)",
                    "totalPickup": "Total (pickup)",
                    "yourAddress": "Your address",
                    "inputYourAddress": "Please enter your address",

                    "pickupAddress": "Pickup address",
                    "ourAddress": "80 Ilia Chavchavadze Avenue, Tbilisi",

                    "shopClosed1": "We are closed today.",
                    "shopClosed2": "Your order will be delivered tomorrow.",
                    "shopClosedTemporarily1": "We are closed for the next few hours.",
                    "shopClosedTemporarily2": "Your order will be delivered from {{openTime}}.",

                    "backendUnavailable": "Online ordering is currently unavailable. Please contact us to place your order ❤️"
                }
            },
            ka: {
                translation: {
                    "typeMacarons": "მაკარონი",
                    "typeCheesecakes": "ჩიზქეიქები",

                    "cheescakePreorded": "ჩიზქეიქები ხელმისაწვდომია წინასწარ შეკვეთაზე Instagram-ზე",
                    "cheescakeMintBlackCurrant": "პიტნა და შავი მოცხარი",
                    "cheescakeMango": "მანგო",
                    "cheescakePistachioRaspberry": "ფისტა ჟოლოთი",
                    "cheescakeSnickers": "სნიკერსი",

                    "introText": "პრემიუმ ფრანგული მაკარონები თბილისში",
                    "location": "თბილისი, საქართველო",
                    "order": "შეკვეთა",
                    "flavours": "ჩვენი მაკარონის არომატები",
                    "allFlavoursAvailable": "ყველა არომატი არის საწყობში და ხელმისაწვდომია შეკვეთისთვის",
                    "orderViaInstagram": "შეკვეთა Instagram-ის საშუალებით",
                    "giftBoxes": "სასაჩუქრე ყუთები",
                    "macarons": "მაკარონი",

                    "excellentQuality": "შესანიშნავი ხარისხი",
                    "premiumIngredients": "პრემიუმ ინგრედიენტები",
                    "almondFlour": "ნუშის ფქვილი BORGES®",
                    "eggWhites": "უფასო სპექტრი კვერცხები GASTRONOME®",
                    "glutenFree": "წებოვანა თავისუფალი",
                    "parmigianoReggiano": "Parmigiano Reggiano DOP",
                    "camembertAndBrie": "ფრანგი კამემბერტი და ბრი",

                    "finestBelgianChocolate": "საუკეთესო ბელგიური შოკოლადი",

                    "frenchFruitPuree": "ფრანგული ხილის პიურე",
                    "mango": "DGF® მანგო",
                    "raspberry": "DGF® ჟოლო",
                    "blackCurrant": "DGF® შავი მოცხარი",
                    "strawberry": "DGF® მარწყვი",

                    "nutPaste100": "თხილის პასტა 100%",
                    "pistachio": "ფისტა",
                    "peanut": "არაქისი",
                    "coconut": "ქოქოსი",
                    "hazelnut": "თხილი",

                    "allergens": "ალერგენები",
                    "dairy": "რძის პროდუქტები",
                    "egg": "კვერცხები",
                    "nuts": "თხილი",
                    "allergensNotice1": "ყველა მაკარონი შეიცავს თხილს, კვერცხს და რძის პროდუქტებს, შემადგენლობა განსხვავდება გემოვნების მიხედვით.",
                    "allergensNotice2": "თუ თქვენ გაქვთ საკვები ალერგია, გთხოვთ ყურადღებით შეისწავლოთ მაკარონის შემადგენლობა (ბარათზე დაწკაპუნებით) ან დაგვიკავშირდეთ განმარტებისთვის.",

                    "ingredients": "შემადგენლობა",

                    "forYourConvenience": "თქვენი მოხერხებულობისთვის",

                    "storageAndService": "შენახვა და მომსახურება",
                    "take15minutesBefore": "გამოიღეთ ყუთი მაცივრიდან მირთმევამდე 15\u00a0წუთით ადრე.",
                    "keepInFridge": "შეინახეთ მაცივრის ბოლოში 5 დღის განმავლობაში.",

                    "delivery": "მიწოდება",
                    "deliveryTerms1": "მიწოდების მეშვეობით Bolt® Delivery ან Yandex® Delivery განაცხადების ბრალი ცალკე.",
                    "deliveryTerms2": "პიკაპის შესაძლებელია.",

                    "composeYourBox": "შეკვეთის მომზადება",
                    "composeYourBoxTerms1": "შეკვეთა მზადდება 1 საათში არსებული არომატიდან.",
                    "composeYourBoxTerms2": "კორპორატიული შეკვეთები მზადდება 3-5\u00a0დღეში.",

                    "workingHours": "სამუშაო საათები",
                    "workingHoursTerms": "ჩვენ ვმუშაობთ ყოველდღე 10-დან\u00a022-მდე.",
                    "yourOrderWillBeProcessedDuringBusinessHours": "თქვენ შეგიძლიათ შეუკვეთოთ ახლა, ჩვენ გამოვა 10:00.",

                    "chooseAnyFlavour": "აირჩიეთ ნებისმიერი არომატი, რომელიც მოგწონთ",

                    "createYourBox": "შექმენით თქვენი ყუთი",
                    "availableBoxes": "ხელმისაწვდომი ყუთები: 5, 10, 16 და\u00a020 მაკარონი",
                    "justKeepAdding": "უბრალოდ გააგრძელეთ არომატის დამატება ან ამოღება, რათა ყუთი უფრო დიდი ან პატარა იყოს",

                    "yourOrder": "თქვენი შეკვეთა",
                    "yourOrderDetailsWillAppear": "თქვენი შეკვეთის დეტალები გამოჩნდება აქ",
                    "total": "სულ",
                    "clearOrder": "წმინდა წესრიგი",
                    "confirmOrder": "დაადასტურეთ შეკვეთა",
                    "completeBoxFirst": "გთხოვთ, შეავსოთ თქვენი ყუთი პირველი ❤️",

                    "yourBox": "შენი ყუთი",
                    "added": "დამატებულია",
                    "removed": "ამოღებულია",
                    "yourBoxIsReady": "თქვენი ყუთი მზად არის შეკვეთა!",
                    "keepAddingBox": "დაამატეთ მეტი, რომ თქვენი ყუთი უფრო დიდი გახდეს.",


                    "minimalOrder": "მინიმალური შეკვეთა 5 მაკარონია",
                    "orderNumber": "შეკვეთის ნომერი",

                    "boxContentReceived": "ჩვენ უკვე მივიღეთ თქვენი ყუთის შინაარსი ❤️",
                    "deliveryDetails": "მიწოდება შესაძლებელია Bolt® Delivery ან Yandex® Delivery საშუალებით.",
                    "youCanPayWithCardOrCash": "შეკვეთის გადახდა შეგიძლიათ ბარათით ან ნაღდი ანგარიშსწორებით პიკაპზე.",

                    "close": "დახურვა",

                    "yourName": "შენი სახელი",
                    "yourPhone": "თქვენი ტელეფონის ნომერი",
                    "yourEmail": "თქვენი E-mail",
                    "messenger": "მესენჯერი",
                    "inputYourName": "გთხოვთ შეიყვანოთ თქვენი სახელი",
                    "inputYourPhone": "გთხოვთ შეიყვანოთ თქვენი ტელეფონის ნომერი",
                    "chooseMessengerType": "გთხოვთ აირჩიოთ მესენჯერი",
                    "longValue": "ღირებულება ძალიან გრძელია",
                    "send": "გაგზავნა",
                    "sent": "გაიგზავნება",

                    "inStockOnly": "საწყობში: ",
                    "tryOtherFlavours": "სცადეთ სხვა არომატები ❤️",

                    "reserved": "დაცულია",
                    "areYourSureToCleanConfirmedOrder": "დარწმუნებული ხართ, რომ გსურთ დადასტურებული შეკვეთის გაწმენდა?",
                    "yes": "დიახ",
                    "no": "არა",

                    "assorted": "ასორტი",

                    "contacts": "კონტაქტი",
                    "weWillBeHappyToHelpYou": "მოხარული ვიქნებით დაგეხმაროთ ნებისმიერ კითხვაში ❤️",
                    "reachUsDaily": "შეგიძლიათ დაგვიკავშირდეთ 09:00\u00a0საათიდან\u00a022:00\u00a0საათამდე\u00a0GMT+4",
                    "instagram": "Instagram",
                    "whatsapp": "WhatsApp",
                    "telegram": "Telegram",
                    "email": "E-mail",

                    "payment": "გადახდა",
                    "weAcceptVisaAndMastercard": "ჩვენ ვიღებთ ონლაინ გადახდებს",

                    "creatingYourOrder": "თქვენი შეკვეთის შექმნა ❤️",
                    "itWillTakeTime": "ამას რამდენიმე წამი დასჭირდება...",
                    "onlinePayment": "ონლაინ გადახდა",

                    "paymentStatus": "გადახდის სტატუსი",
                    "paymentStatus_CREATED": "გადახდის მოთხოვნა იქმნება",
                    "paymentStatus_PROCESSING": "გადახდა მუშავდება",
                    "paymentStatus_COMPLETED": "გადახდის პროცესი დასრულდა",
                    "paymentStatus_REJECTED": "გადახდის პროცესი წარმატებით დასრულდა",
                    "paymentStatus_REFUND_REQUESTED": "თანხის დაბრუნება მოითხოვება",
                    "paymentStatus_REFUNDED": "გადახდის თანხა დაბრუნდა",
                    "paymentStatus_REFUNDED_PARTIALLY": "გადახდის თანხა ნაწილობრივ ანაზღაურდა",
                    "paymentStatus_ERROR": "გადახდის შეცდომა (გთხოვთ, დაგვიკავშირდეთ დეტალები)",
                    "paymentStatus_PARTIAL_COMPLETED": "წინასწარი ავტორიზაცია გადახდის ნაწილობრივი თანხა უკვე დაადასტურა წარმატებით",

                    "receipt": "ჩეკი",

                    "treatYourself": "მკურნალობა თავს",

                    "districtVakeAndBagebi": "ვაკე, ბაგები",
                    "districtSaburtaloAndVeraAndChughureti": "საბურთალო, ვერე, ჩუღურეთი",
                    "districtDidubeAndSololakiAndDzveli": "დიდუბე, სოლოლაკი, ძველი თბილისი",
                    "districtAvlabari": "ავლაბარი",
                    "districtIsaniAndVarketili": "ისანი, ვარკეთილი",
                    "districtOther": "კიდევ ერთი ტერიტორია",

                    "orderComment": "კომენტარი",
                    "pickup": "პიკაპი",
                    "yourDeliveryAddressDistrict": "თქვენი ტერიტორია",
                    "totalWithDelivery": "სულ (მიწოდებით)",
                    "totalPickup": "სულ (პიკაპი)",
                    "yourAddress": "თქვენი მისამართი",
                    "inputYourAddress": "გთხოვთ შეიყვანოთ თქვენი მისამართი",

                    "pickupAddress": "პიკაპის მისამართი",
                    "ourAddress": "თბილისი, ჭავჭავაძის 80",

                    "shopClosed1": "ჩვენ დღეს არ ვმუშაობთ.",
                    "shopClosed2": "თქვენი შეკვეთა ხვალ გადმოგეცემათ.",
                    "shopClosedTemporarily1": "ჩვენ არ ვმუშაობთ მომდევნო რამდენიმე საათის განმავლობაში.",
                    "shopClosedTemporarily2": "თქვენი შეკვეთა {{openTime}} საათიდან გადმოგეცემათ.",

                    "backendUnavailable": "ონლაინ შეკვეთა ამჟამად მიუწვდომელია. გთხოვთ დაგვიკავშირდეთ თქვენი შეკვეთის განსათავსებლად ❤️"
                }
            },
            ru: {
                translation: {
                    "typeMacarons": "Макароны",
                    "typeCheesecakes": "Чизкейки",

                    "cheescakePreorded": "Чизкейки доступны по предзаказу в Instagram",
                    "cheescakeMintBlackCurrant": "Мята и чёрная смородина",
                    "cheescakeMango": "Манго",
                    "cheescakePistachioRaspberry": "Фисташка с малиной",
                    "cheescakeSnickers": "Сникерс",

                    "introText": "Премиальные французские макароны в\u00a0Тбилиси",
                    "location": "Тбилиси, Грузия",
                    "order": "Заказать",
                    "flavours": "Вкусы наших макарон",
                    "allFlavoursAvailable": "В наличии и доступны для заказа",
                    "orderViaInstagram": "Заказать в Instagram",
                    "giftBoxes": "Подарочные коробки",
                    "macarons": "макарон",

                    "excellentQuality": "Исключительное качество",
                    "premiumIngredients": "Премиальные ингредиенты",
                    "almondFlour": "Миндальная мука BORGES®",
                    "eggWhites": "Яйца свободного выгула GASTRONOME®",
                    "glutenFree": "Не содержит глютен",
                    "parmigianoReggiano": "Parmigiano Reggiano DOP",
                    "camembertAndBrie": "Французский камамбер и бри",

                    "finestBelgianChocolate": "Бельгийский шоколад",

                    "frenchFruitPuree": "Французское фруктовое пюре",
                    "mango": "DGF® Манго",
                    "raspberry": "DGF® Малина",
                    "blackCurrant": "DGF® Чёрная смородина",
                    "strawberry": "DGF® Клубника",

                    "nutPaste100": "100% ореховая паста",
                    "pistachio": "Фисташка",
                    "peanut": "Арахис",
                    "coconut": "Кокос",
                    "hazelnut": "Фундук",

                    "allergens": "Аллергены",
                    "dairy": "Молочные продукты",
                    "egg": "Яйца",
                    "nuts": "Орехи",
                    "allergensNotice1": "Все макароны содержат орехи, яйца и молочные продукты, состав варьируется в зависимости от вкуса.",
                    "allergensNotice2": "Если у вас есть пищевая аллергия — пожалуйста, внимательно изучите состав макарона (по нажатию на карточку) или свяжитесь с нами для уточнения.",

                    "ingredients": "Ингредиенты",

                    "forYourConvenience": "Для вашего удобства",

                    "storageAndService": "Хранение и подача",
                    "take15minutesBefore": "Достаньте коробку из холодильника за 15\u00a0минут до подачи.",
                    "keepInFridge": "Храните в нижней части холодильника, не более 5\u00a0дней.",

                    "delivery": "Доставка",
                    "deliveryTerms1": "Доставка через Bolt® Delivery или Yandex® Delivery, оплачивается отдельно.",
                    "deliveryTerms2": "Доступен самовывоз.",

                    "composeYourBox": "Сборка заказа",
                    "composeYourBoxTerms1": "Заказ собирается в течение 1\u00a0часа из вкусов в наличии.",
                    "composeYourBoxTerms2": "Корпоративные заказы готовятся в течение 3-5\u00a0дней.",

                    "workingHours": "Режим работы",
                    "workingHoursTerms": "Мы работаем ежедневно с\u00a010\u00a0до\u00a022\u00a0часов.",
                    "yourOrderWillBeProcessedDuringBusinessHours": "Вы можете сделать заказ сейчас, мы доставим его с 10:00.",

                    "chooseAnyFlavour": "Вы можете выбрать любую комбинацию вкусов из наличия",

                    "createYourBox": "Создайте свою коробочку",
                    "availableBoxes": "Доступные размеры: 5, 10, 16 или 20\u00a0макарон",
                    "justKeepAdding": "Добавляйте или убирайте вкусы, чтобы сделать коробку больше или меньше",

                    "yourOrder": "Ваш заказ",
                    "yourOrderDetailsWillAppear": "Здесь будут детали вашего заказа",
                    "total": "Итого",
                    "clearOrder": "Очистить заказ",
                    "confirmOrder": "Подтвердить заказ",
                    "completeBoxFirst": "Заполните коробку любимыми вкусами ❤️",

                    "yourBox": "Ваша коробка",
                    "added": "добавлено",
                    "removed": "убрано",
                    "yourBoxIsReady": "Ваша коробка готова для заказа!",
                    "keepAddingBox": "Добавьте еще, чтобы сделать коробку больше.",


                    "minimalOrder": "Минимальный заказ — 5 макарон",
                    "orderNumber": "Номер заказа",

                    "boxContentReceived": "Мы получили ваш заказ ❤️",
                    "deliveryDetails": "Доставка доступна через Bolt® Delivery или Yandex® Delivery.",
                    "youCanPayWithCardOrCash": "Вы можете оплатить заказ картой или наличными при самовывозе.",

                    "close": "Закрыть",

                    "yourName": "Ваше имя",
                    "yourPhone": "Ваш номер телефона",
                    "yourEmail": "Ваш E-mail",
                    "messenger": "Мессенджер",
                    "inputYourName": "Пожалуйста, введите ваше имя",
                    "inputYourPhone": "Пожалуйста, введите ваш номер телефона",
                    "chooseMessengerType": "Пожалуйста, выберите мессенджер",
                    "longValue": "Значение слишком длинное",
                    "send": "Отправить",
                    "sent": "Отправлено",

                    "inStockOnly": "В наличии: ",
                    "tryOtherFlavours": "Попробуйте другие вкусы ❤️",

                    "reserved": "зарезервировано",
                    "areYourSureToCleanConfirmedOrder": "Вы уверены, что хотите очистить подтвержденный заказ?",
                    "yes": "Да",
                    "no": "Нет",

                    "assorted": "Ассорти",

                    "contacts": "Контакты",
                    "weWillBeHappyToHelpYou": "Мы будем рады помочь вам с любым вопросом ❤️",
                    "reachUsDaily": "Вы можете связаться с нами с 09:00 до 22:00 GMT+4",
                    "instagram": "Instagram",
                    "whatsapp": "WhatsApp",
                    "telegram": "Telegram",
                    "email": "E-mail",

                    "payment": "Оплата",
                    "weAcceptVisaAndMastercard": "Мы принимаем онлайн платежи",

                    "creatingYourOrder": "Создаём Ваш заказ ❤️",
                    "itWillTakeTime": "Это займёт несколько секунд...",
                    "onlinePayment": "Онлайн оплата",

                    "paymentStatus": "Статус оплаты",
                    "paymentStatus_CREATED": "Платёж создан",
                    "paymentStatus_PROCESSING": "Платёж обрабатывается",
                    "paymentStatus_COMPLETED": "Процесс оплаты завершен успешно",
                    "paymentStatus_REJECTED": "Процесс оплаты завершен ошибкой",
                    "paymentStatus_REFUND_REQUESTED": "Запрошен возврат",
                    "paymentStatus_REFUNDED": "Платёж возвращён",
                    "paymentStatus_REFUNDED_PARTIALLY": "Платёж возвращён частично",
                    "paymentStatus_ERROR": "Ошибка платежа (пожалуйста, свяжитесь с нами для уточнения деталей)",
                    "paymentStatus_PARTIAL_COMPLETED": "Предварительная авторизация частичной суммы платежа была успешно подтверждена",

                    "receipt": "Чек",
                    "treatYourself": "Побалуйте себя",

                    "districtVakeAndBagebi": "Ваке, Багеби",
                    "districtSaburtaloAndVeraAndChughureti": "Сабуртало, Вера, Чугурети",
                    "districtDidubeAndSololakiAndDzveli": "Дидубе, Сололаки, Старый Тбилиси",
                    "districtAvlabari": "Авлабари",
                    "districtIsaniAndVarketili": "Исани, Варкетили",
                    "districtOther": "Другой район",

                    "orderComment": "Комментарий",
                    "pickup": "Самовывоз",
                    "yourDeliveryAddressDistrict": "Ваш район",
                    "totalWithDelivery": "Итого (с доставкой)",
                    "totalPickup": "Итого (самовывоз)",
                    "yourAddress": "Ваш адрес",
                    "inputYourAddress": "Пожалуйста, введите ваш адрес",

                    "pickupAddress": "Адрес самовывоза",
                    "ourAddress": "проспект Ильи Чавчавадзе, 80, Тбилиси",

                    "shopClosed1": "Мы не работаем сегодня.",
                    "shopClosed2": "Ваш заказ будет доставлен завтра.",
                    "shopClosedTemporarily1": "Мы не работаем в ближайшие несколько часов.",
                    "shopClosedTemporarily2": "Ваш заказ будет доставлен с {{openTime}}.",

                    "backendUnavailable": "Онлайн заказы временно недоступны. Пожалуйста, напишите нам для оформления заказа ❤️"
                }
            },
        },
        lng: localStorage.getItem('language') || 'en',
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

export default i18n;