import callASATools, {callBackend2} from "./utils";

// @ts-ignore
export async function sendRequest(name) {
    let uri = '/api/telegram/send';
    let body = {
        message: name
    }

    let headers = {
        "Content-Type": "application/json"
    }

    return callBackend2(uri, body, headers)
}

// @ts-ignore
export async function sendOrder(body) {
    let uri = '/api/order/create';

    let headers = {
        "Content-Type": "application/json"
    }

    return callASATools(uri, "POST", body)
}

// @ts-ignore
export async function createPayment(orderId) {
    let uri = '/api/order/payment/' + orderId;

    let headers = {
        "Content-Type": "application/json"
    }

    return callASATools(uri, "POST")
}

// @ts-ignore
export async function getStocks() {
    let uri = '/api/stock';
    return callASATools(uri, 'GET')
}

// @ts-ignore
export async function getShop() {
    let uri = '/api/shop';
    return callASATools(uri, 'GET')
}

// @ts-ignore
export async function getOrderDetails(id) {
    let uri = '/api/order/details/' + id;
    return callASATools(uri, 'GET')
}