import {
    Button,
    Col,
    Descriptions,
    DescriptionsProps,
    Row,
    Space,
    Spin,
    Statistic
} from "antd";
import React, {FC, useEffect, useState} from "react";
import {getOrderDetails} from "../../api/api";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import TermsAndConditions from "../main/component/TermsAndConditions";
import {Box} from "../order/component/ShowBox";
import {useSearchParams} from "react-router-dom";
import Contacts from "../main/component/Contacts";

function OrderDetailsPage() {
    const {t} = useTranslation();

    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        updateData()
    }, [])

    let updateData = () => {
        setLoading(true)
        getOrderDetails(searchParams.get("id")).then(([response, error]) => {
            if (response) {
                setOrder(response)
            }
            setLoading(false)
        });
    }

    const getDataSourceDescriptions = () => {
        let arr: DescriptionsProps['items'] = [];

        if (order) {
            let orderItems = order["order"]

            for (let key in (orderItems ? orderItems : [])) {
                let orderItem = orderItems[key];

                let macaronName = i18n.language === 'en'
                    ? orderItem["name"]
                    : i18n.language === 'ka' ? orderItem["nameKa"] : orderItem["nameRu"]

                let ord = {
                    key: key,
                    label: macaronName,
                    children: <p>{orderItem["quantity"]}</p>,
                    contentStyle: {
                        paddingTop: "0",
                        paddingBottom: "0"
                    }
                }

                arr.push(ord);
            }
        }

        return arr
    }

    const OrderDetails: FC = () => {
        let delivery = order ? order["clientDelivery"] : "unknown"
        let totalPrice = Number(order ? order["price"] : 0)
        if (delivery === "delivery") {
            totalPrice += (Number(order ? order["deliveryPrice"] : 0));
        }

        return (
            <>
                <Statistic
                    groupSeparator={""}
                    title={t('orderNumber')}
                    value={order ? order["orderNumber"] : "***"}/>

                <Descriptions size={"small"}
                              column={1}
                              style={{marginTop: "1em", marginBottom: "20px"}}
                              bordered items={
                    [
                        {
                            label: t('yourName'),
                            children: order ? order["clientName"] : "***",
                        },
                        {
                            label: t('yourPhone'),
                            children: order ? order["clientPhone"] : "***",
                        },
                        // {
                        //     label: t('messenger'),
                        //     children: order ? order["clientMessenger"] : "***",
                        // },
                        // {
                        //     label: t('yourEmail'),
                        //     children: order ? order["clientEmail"] : "***",
                        // },
                        // {
                        //     label: t('orderComment'),
                        //     children: order ? order["clientComment"] : "-",
                        // },
                        {
                            label: t('delivery'),
                            children: order ? t(order["clientDelivery"]) : "***",
                        },
                        {
                            label: t('yourAddress'),
                            children: order ? order["clientDeliveryAddress"] : "-",
                        },
                        // {
                        //     label: t('yourDeliveryAddressDistrict'),
                        //     children: order ? t(order["clientDeliveryAddressDistrict"]) : "-",
                        // },
                        {
                            label: t('paymentStatus'),
                            children: order ? t('paymentStatus_' + order["paymentStatus"]) : "UNKNOWN",
                        }
                    ]
                }
                />

                <Descriptions
                    title={<Box
                        boxSize={order ? order["boxSize"] : 0}
                        ordered={order ? order["boxSize"] : 0}
                    />}
                    size={"small"}
                    column={1}
                    bordered items={getDataSourceDescriptions()}/>

                {
                    delivery === "delivery"
                        ? <Statistic style={{marginTop: "2em", marginBottom: "1em"}} title={t('totalWithDelivery')} value={
                            (totalPrice) + " ₾"
                        }/>
                        : <Statistic style={{marginTop: "2em", marginBottom: "1em"}} title={t('totalPickup')} value={(totalPrice) + " ₾"}/>
                }

                {order && order["receiptUrl"]
                    ? <>
                        {order && order["paymentStatus"] === "CREATED"
                            ? <Button type="primary" style={{marginTop: "1em"}}
                                      href={order["paymentUrl"]}>{t('onlinePayment')}</Button>
                            : <Button type="primary" style={{marginTop: "1em"}}
                                      href={order["receiptUrl"]} target="_blank" rel="noopener noreferrer">{t('receipt')}</Button>
                        }
                    </>
                    : <></>
                }
            </>
        )
    }

    return (
        <>
            <Row justify="center" style={{padding: "1em", paddingBottom: 0}}>
                <Col
                    xs={{span: 24}}
                    sm={{span: 24}}
                    md={{span: 22}}
                    lg={{span: 20}}
                    xl={{span: 19}}
                    xxl={{span: 16}}
                >
                    <Row justify={"center"}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={10}>
                            <Space direction={"vertical"}>
                                <h2 style={{marginTop: 0}}>{t('yourOrder')}</h2>

                                {loading
                                    ? <Spin/>
                                    : <OrderDetails/>
                                }
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="center" style={{padding: "1em", paddingBottom: 0}}>
                <Col
                    xs={{span: 24}}
                    sm={{span: 24}}
                    md={{span: 22}}
                    lg={{span: 20}}
                    xl={{span: 19}}
                    xxl={{span: 16}}
                >
                    <Contacts/>

                    <TermsAndConditions/>
                </Col>
            </Row>
        </>
    )
}

export default OrderDetailsPage;