export function callBackend2(uri, body, headers) {
    if (body) {
        return fetch(uri, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
    } else {
        return fetch(uri, {
            method: 'GET',
            headers: headers
        });
    }
}

export default async function callASATools(uri, method, body) {
    var headers = {}

    if (method === "GET") {
        // let urlParams = new URLSearchParams(parameters).toString()
        // if (urlParams) {
        //     uri += "?" + urlParams
        // }
    } else {
        headers = {"content-type": "application/json"}
        body = JSON.stringify(body)
    }

    let response = await fetch(uri, {
        method: method,
        headers: headers,
        body: body
    })

    try {
        if (response.status === 200 || response.status === 400) {
            let responseJSON = await response.json()

            return Promise.all([responseJSON, null])
        } else if (response.status === 500) {
            return Promise.all([null, "Internal server error. We will fix it soon."])
        } else if (response.status === 502) {
            return Promise.all([null, "Too many requests on a server. Please try again later."])
        } else if (response.status === 504) {
            return Promise.all([null, "The request took too much time to execute."])
        } else if (response.status === 401) {
            return Promise.all([null, "Unauthorized", response.status])
        } else {
            return Promise.all([null, "Unhandled status code", response.status])
        }
    } catch (exception) {
        return Promise.all([null, "Internal server error. We will fix it soon."])
    }
}