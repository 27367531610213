import React, {lazy} from 'react';
import {Col, Layout, Row} from "antd";
import LanguageSelector from "./page/main/component/LanguageSelector";
import Footer from "./page/main/component/Footer";
import {Content, Header} from 'antd/lib/layout/layout';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import OrderDetailsPage from "./page/order-details/OrderDetailsPage";
import {useTranslation} from "react-i18next";
import {SmallMacaronIcon} from "./page/main/component/MacaronIcon";

const MainPage = lazy(() => import("./page/main/MainPage"));
const Order = lazy(() => import("./page/order/OrderPage"));

function App() {
    const {t} = useTranslation();
    return (
        <>
            <a href="/order" style={{textDecoration: "none"}}>
                <Row justify={"center"} style={{backgroundColor: "#B99095"}}>
                    <Col>
                        <p style={{color: "#fff"}}>{t('treatYourself')} <SmallMacaronIcon/></p>
                    </Col>
                </Row>
            </a>

            <Layout style={{textAlign: "center", minHeight: "100vh"}}>
                <Header
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        width: '100%',
                        borderBottom: "2px solid #f0f0f0",
                        backgroundColor: "whitesmoke"
                    }}
                >
                    <Row justify={"space-between"} align={"middle"}>
                        <Col style={{width: 84}}>
                        </Col>
                        <Col style={{paddingLeft: "1em"}}>
                            <a href="/" style={{textDecoration: "none"}}>
                                <div>
                                    <div style={{lineHeight: "1em", color: "#B99095"}}>L'Atelier de Macaron</div>
                                    <div style={{lineHeight: "1em", fontSize: "0.8em", marginTop: "0.5em", color: "#B99095"}}>თბილისი</div>
                                </div>
                            </a>
                        </Col>
                        <Col style={{paddingRight: "1em"}}>
                            <LanguageSelector/>
                        </Col>
                    </Row>
                </Header>

                <Content>
                    <Router>
                        <Routes>
                            <Route path="/order" element={<Order/>}/>
                            <Route path="/order/details" element={<OrderDetailsPage/>}/>
                            <Route path="/" element={<MainPage/>}/>
                        </Routes>
                    </Router>
                </Content>

                <Footer/>
            </Layout>
        </>
    );
}

export default App;
