import {Card, Col, Image, List, Row} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

function TermsAndConditions() {
    const {t} = useTranslation();

    return (
        <div>
            <h2>{t('forYourConvenience')}</h2>
            <Row gutter={16}>
                <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}
                     style={{marginBottom: "1em"}}>
                    <Image preview={false} style={{borderRadius: "8px", aspectRatio: "1078 / 1243"}}
                           src="/quality/box_in_hand.webp" loading="lazy"/>
                </Col>

                {/*<Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}*/}
                {/*     style={{marginBottom: "1em"}}>*/}
                {/*    <Card title={t('delivery')} bordered={false}*/}
                {/*          style={{height: "100%", display: "flex", flexDirection: "column"}}*/}
                {/*          bodyStyle={{flexGrow: 1, display: "flex", alignItems: "center"}}*/}
                {/*    >*/}
                {/*        <List size="small" style={{textAlign: "left", width: "100%"}}>*/}
                {/*            <List.Item>{t('deliveryTerms1')}</List.Item>*/}
                {/*            <List.Item>{t('deliveryTerms2')}</List.Item>*/}
                {/*        </List>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}
                     style={{marginBottom: "1em"}}>
                    <Card title={t('composeYourBox')} bordered={false}
                          style={{height: "100%", display: "flex", flexDirection: "column"}}
                          bodyStyle={{flexGrow: 1, display: "flex", alignItems: "center"}}
                    >
                        <List size="small" style={{textAlign: "left", width: "100%"}}>
                            <List.Item>{t('composeYourBoxTerms1')}</List.Item>
                            <List.Item>{t('composeYourBoxTerms2')}</List.Item>
                        </List>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}
                     style={{marginBottom: "1em"}}>
                    <Card title={t('storageAndService')} bordered={false}
                          style={{height: "100%", display: "flex", flexDirection: "column"}}
                          bodyStyle={{flexGrow: 1, display: "flex", alignItems: "center"}}
                    >
                        <List size="small" style={{textAlign: "left", width: "100%"}}>
                            <List.Item>{t('take15minutesBefore')}</List.Item>
                            <List.Item>{t('keepInFridge')}</List.Item>
                        </List>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}
                     style={{marginBottom: "1em"}}>
                    <Card title={t('workingHours')} bordered={false}
                          style={{height: "100%", display: "flex", flexDirection: "column"}}
                          bodyStyle={{flexGrow: 1, display: "flex", alignItems: "center"}}
                    >
                        <List size="small" style={{textAlign: "left", width: "100%"}}>
                            <List.Item>{t('workingHoursTerms')}</List.Item>
                        </List>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default TermsAndConditions;