import {Button, Col, Row, Space} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import {InstagramOutlined, MailOutlined, SendOutlined, WhatsAppOutlined} from "@ant-design/icons";

function Contacts() {
    const {t} = useTranslation();

    return (
        <>
            <h2>{t('contacts')}</h2>

            <p>{t('weWillBeHappyToHelpYou')}</p>
            <p>{t('reachUsDaily')}</p>

            <ContactButtons/>
        </>
    )
}

export function ContactButtons() {
    return (
        <Row justify={"center"}>
            <Col style={{marginBottom: "1em"}}>
                <Space wrap style={{justifyContent: "center"}}>
                    <Button
                        href="https://ig.me/m/atelier_de_macaron"><InstagramOutlined/> Instagram</Button>
                    <Button href="https://t.me/danatattooist"><SendOutlined/> Telegram</Button>
                    <Button
                        href="https://wa.me/995555398750"><WhatsAppOutlined/> WhatsApp</Button>
                    <Button
                        href="mailto:order@macaron.ge"><MailOutlined/> E-mail</Button>
                </Space>
            </Col>
        </Row>
    )
}

export default Contacts;