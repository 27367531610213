import {Select} from "antd";
import React from "react";
import i18n from "i18next";

function LanguageSelector() {
    const changeLanguage = lng => {
        localStorage.setItem('language', lng)
        i18n.changeLanguage(lng);
    };

    return (
        <Select
            defaultValue={localStorage.getItem('language') || 'en'}
            // style={{width: 135}}
            style={{width: 84}}
            onChange={(lang) => changeLanguage(lang)}
            options={[
                {value: 'en', label: '🇬🇧 En'},
                {value: 'ka', label: '🇬🇪 ქა'},
                {value: 'ru', label: '🇷🇺 Ру'}
            ]}
        />
    )
}

export default LanguageSelector;